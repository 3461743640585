import * as React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import App from './App';
import { store } from './store';
import './index.scss';

import { launchdarkly, URLS } from './utils/constants';
import { fetch } from './utils/fetch';

(async () => {
  const { data: accountData } = (await fetch.get(
    `${URLS.API_URL}/account?withFeatures=true`,
  ));
  const {
    data: {
      id, name, email,
    },
  } = await fetch.get(`${URLS.API_URL}/users/me`);
  const LDProvider = await asyncWithLDProvider({
    clientSideID: launchdarkly.key,
    context: {
      kind: 'user',
      key: id,
      name,
      email,
      companyId: accountData.id,
      companyCreationDate: Math.floor(new Date(accountData.creationDate).getTime() / 1000),
      creationDate: new Date(accountData.creationDate).getTime(),
    },
  });
  ReactDOM.render(
    <LDProvider>
      <Provider store={store}>
        <App />
      </Provider>
    </LDProvider>,
    document.getElementById('root'),
  );
})();
